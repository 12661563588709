<template>
<div >
	

			<v-card>
				<v-toolbar flat color="white">
                    <v-toolbar-title>Buscar</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    >
                    </v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="searchCodigo"
                        append-icon="mdi-magnify"
                        label="Codigo"
                        single-line
                        
                        class='mt-3'
                        @click:append=cargarLista(true)
                    ></v-text-field>
                    <v-text-field
                        v-model="searchProducto"
                        append-icon="mdi-magnify"
                        label="Producto"
                        single-line
                        
                        class='mt-3'
                        @click:append=cargarLista(true)
                    ></v-text-field>
                    <v-select :items="listaBodegas"
                    	label="Bodega"
                    	dense
                    	item-text="facility_name"
          				item-value="facility_id"
                    	v-model="searchBodega"
                    	append-icon="mdi-magnify"
                    	class='mt-3'
                        @click:append=cargarLista(true)
                    	solo ></v-select>
                    
                    <v-text-field
                        v-model="searchLote"
                        append-icon="mdi-magnify"
                        label="Lote"
                        single-line                        
                        class='mt-3'
                        @click:append=cargarLista(true)
                    ></v-text-field>
    </v-toolbar>
	<v-toolbar flat color="white">
		<v-btn  color="primary" small  class="ma-2 white--text" 
		@click="exportar()"
		>
			<v-icon left >mdi-microsoft-excel</v-icon>
		Exportar
		</v-btn>
	</v-toolbar>
	
	  <v-row>
         <v-col md="12" sm="12">
         <v-data-table
			    :headers="headers"
			    :loading="loadingTable"
			    :items="lista"
			    :items-per-page="1000"
			    hide-default-footer
			    class="elevation-1"
			  >
			  </v-data-table>
			  </v-col>
         </v-row>
         </v-card>
</div>
</template>


<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'

export default {
	name: "ArqueoBodegaComponent",
	
	data: ()=> ({
    	 headers: [
    		 { text: 'Opciones', value: 'opcion' , align:'center', width:40 },
    		  
             { text: 'Bodega', value: 'bodega' },
             { text: 'Codigo', value: 'product_id' },                                       
             { text: 'Producto', value: 'nombre_producto'},             
             { text: 'Marca', value: 'marca'},
             { text: 'Categoria', value: 'categoria'},
             { text: 'Cantidad total', value: 'unidades_totales' , align:'right'},
             { text: 'Cantidad disp.', value: 'unidades_disponibles' , align:'right'},
             { text: 'Lote', value: 'lote', align:'start'},
             { text: 'Fecha caducidad', value: 'fecha_caducidad' , align:'center'},
             
           ],
           lista :[],
           listaBodegas: [],
           
           options: {},
           dialog: false,
           notifications: false,
           sound: true,
           widgets: true,
          
            //itemsEstado: [ {'estadoId': 'INVOICE_READY','estadoNombre':'Listo'},{'estadoId':'INVOICE_PAID','estadoNombre': 'Pagado'},{'estadoId':'INVOICE_IN_PROCESS','estadoNombre': 'En proceso'}],
            
             currentPage: 1,
             pageCount: 1,
             itemsPerPage: 20,

             searchCodigo: "",
             searchProducto: "",
             searchBodega: "",
             searchLote: ""
    }),
    computed: {
    	...mapState('master',['loadingTable','user','tenantId']),
    	
    	...mapGetters('access', ['btnAbrirCaja'])
    },
    methods: {
    	...mapMutations('master',['setUrl','setOverlay','setMenu','setTitleToolbar','setLoadingTable']),
	   	
	    ...mapActions('master',['requestApi']),
	     
	    cargarLista(filtrar) {
            
    		if(filtrar == true) {
    			this.currentPage = 1
    		}
            this.setLoadingTable(true)
            this.setUrl('arqueo-bodega')
            this.requestApi({
                method : 'GET',
                data : {
                	"bodega_id": this.searchBodega,
                	"producto": this.searchProducto,
                	"codigo_producto": this.searchCodigo,
                	"lote" : this.searchLote,
			        "page": this.currentPage, 
			        "page_count": this.pageCount, 
			        "page_size": this.itemsPerPage,
			        
			    }
            }).then(res=>{
            	//console.log(res.data.lista);			        			    		
            	this.lista = res.data._embedded.arqueo_bodega;
            	//this.pageCount = res.data.page_count;
            	this.setLoadingTable(false)
            }).then(()=>{
	              
            })
        	  

        },
        
        exportar() {
        	 
        	this.setUrl('arqueo-bodega')
            this.requestApi({
                method : 'POST',
                data : {
                	
                	"bodega_id": this.searchBodega,
                	"producto": this.searchProducto,
                	"codigo_producto": this.searchCodigo,
                	"lote" : this.searchLote,
			        "page": this.currentPage, 
			        "page_count": this.pageCount, 
			        "page_size": this.itemsPerPage,
			    }
            }).then(res=>{
            	//console.log(res.data);			        			    		
            	
            	var a = document.createElement("a");
            	a.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ res.data.datos;
            	a.download = res.data.archivo;
            	a.click();
            	
            }).then(()=>{
	              
            })
            
        	

        },
        cargarListaBodegas() {
        	this.setUrl('bodega')
            this.requestApi({
                method : 'GET',
                data : {
                	"activas" : "1"			        
			    }
            }).then(res=>{
            				        			    		
            	this.listaBodegas = res.data._embedded.bodega;
            	            			
            }).then(()=>{
	              
            })
        	
        }
	},
	       

    mounted() {
    	 
    	 this.cargarListaBodegas()
    	 this.setTitleToolbar('ARQUEO BODEGA')
         this.cargarLista(false);
    },
    
    watch: {
    	
    	searchNumber : {
    		handler() {
    			this.cargarLista(true)
    		}
    	},
    	searchEstado : {
    		 handler () {
                 this.cargarLista(true)
             },
    	}
    }

    
  }

</script>